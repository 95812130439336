import React from 'react'
import Zoom from '@material-ui/core/Zoom'
import MUIFab from '@material-ui/core/Fab'
import useMotionNavOffsetStyle from '../hooks/use-motion-nav-offset-style'
import FabContainer from './fab-container'

type FabProps = {
  onClick?: () => void
  children?: React.ReactNode
  isIn?: boolean
}
const Fab = ({ onClick, children, isIn }: FabProps) => {
  const props = useMotionNavOffsetStyle({ dodge: 'none' })

  return (
    <Zoom in={isIn ?? true}>
      <FabContainer
        style={(props as any)?.style ? (props as any).style : undefined}
      >
        <MUIFab color="secondary" onClick={onClick}>
          {/*TODO: fix type*/}
          {children as any}
        </MUIFab>
      </FabContainer>
    </Zoom>
  )
}

export default Fab
