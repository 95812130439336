import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import Youtube, { PlayerVars } from 'react-youtube'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import { AiOutlineStop, AiOutlineLoading3Quarters } from 'react-icons/ai'
import { SettingsContext } from '../settings/settings-context'
import Fab from 'src/common/components/fab'

type YoutubeAudioButtonProps = {
  videoId: string
  playerVars?: PlayerVars
}
const YoutubeAudioButton = ({
  videoId,
  playerVars,
}: YoutubeAudioButtonProps) => {
  const [{ autoplaySounds }] = useContext(SettingsContext)
  const [loadYoutube, setLoadYoutube] = useState(false)
  const [isYoutubeReady, setIsYoutubeReady] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [isBuffering, setIsBuffering] = useState(false)
  //TODO: the object is redundant
  const videoRef = useRef<{ video: any }>({ video: null })

  //Workaround to ensure that Youtube video autoplays.
  useEffect(() => {
    setLoadYoutube(true)
  }, [])

  const handleClick = () => {
    if (isVideoPlaying) {
      videoRef.current.video.pauseVideo()
      videoRef.current.video.seekTo(0)
    } else {
      videoRef.current.video.playVideo()
    }
  }

  const handleReady = (e: any) => {
    videoRef.current.video = e.target
    setIsYoutubeReady(true)
  }

  const handleStateChange = (data: number) => {
    //Data number code is from Youtube embeded api.
    //I forgot to save the link, but it's searchable.
    switch (data) {
      case 1:
        setIsVideoPlaying(true)
        setIsBuffering(false)
        break
      case 2:
        setIsVideoPlaying(false)
        break
      case 3:
        setIsBuffering(true)
        break
      case 5:
        setIsVideoPlaying(false)
        setIsBuffering(false)
        break
    }
  }

  return (
    <>
      {loadYoutube && (
        <YoutubeWrapper>
          <Youtube
            videoId={videoId}
            onReady={handleReady}
            onStateChange={(e) => handleStateChange(e.data)}
            onEnd={() => setIsVideoPlaying(false)}
            opts={{
              width: '560',
              height: '315',
              playerVars: {
                autoplay: autoplaySounds,
                playsinline: 1,
                ...playerVars,
              },
            }}
          />
        </YoutubeWrapper>
      )}
      <Fab onClick={handleClick} isIn={isYoutubeReady}>
        <VolumeUpIcon />
        {isBuffering && (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        )}
        {isVideoPlaying && (
          <StopWrapper>
            <AiOutlineStop />
          </StopWrapper>
        )}
      </Fab>
    </>
  )
}

export default YoutubeAudioButton

const YoutubeWrapper = styled.div`
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
  overflow: hidden;
`
const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const LoadingWrapper = styled(IconWrapper)`
  font-size: 3.515rem;
`
const LoadingSpinner = styled(AiOutlineLoading3Quarters)`
  color: ${(p) => p.theme.palette.primary.main};
  animation: rotation 1s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
const StopWrapper = styled(IconWrapper)`
  font-size: 4.73em;
  color: ${(p) => p.theme.palette.primary.main};
`
